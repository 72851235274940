import React, { useEffect } from "react";
import Sidebar from "../sidebar/sidebar";
import AuthAPI from "../../api/auth";
import { TopAppBarUser } from "../appbar/appBarUser";
import EDU_PRO from "../../svg/edu-pro.svg";
import MY_GOALS from "../../svg/goals.svg";
import ASSIGNMENT from "../../svg/assignment.svg";
import RESUME from "../../svg/resume.svg";
import PROGRESS from "../../svg/progress.svg";
import MOCK from "../../svg/mock.svg";
import REWARD from "../../svg/reward.svg";
import DASHBOARD from "../../svg/dashboard.svg";

import EDU_PRO_ACTIVE from "../../svg/edu-pro_active.svg";
import MY_GOALS_ACTIVE from "../../svg/goals_active.svg";
import ASSIGNMENT_ACTIVE from "../../svg/assignment_active.svg";
import RESUME_ACTIVE from "../../svg/resume_active.svg";
import PROGRESS_ACTIVE from "../../svg/progress_active.svg";
import MOCK_ACTIVE from "../../svg/mock_active.svg";
import REWARD_ACTIVE from "../../svg/reward_active.svg";
import DASHBOARD_ACTIVE from "../../svg/dashboard_active.svg";
import EducrackAPI from "@lipihipi/client-sdk";

const contentWidth = "calc(100% - 160px)";

const Layout = ({
  children,
  onCourseSelect,
  selectedCourse,
}: {
  children: React.ReactNode;
  onCourseSelect: any;
  selectedCourse: any;
}) => {
  const [drawer, setDrawer] = React.useState(false);
  const [courseResouceValue, setCourseResourceValue] = React.useState(true);
  const [categoryList, setCategoryList] = React.useState([
    {
      name: "",
      link: "",
    },
  ]);
  const [menuList, setMenuList] = React.useState<any>([]);
  const currentUser = AuthAPI.getCurrentUser();

  useEffect(() => {
    EducrackAPI.course.list({}).then(({ status, data }: any) => {
      if (status === 200) {
        data?.courses.map((value: any) => {
          if (value._id == selectedCourse._id) {
            setCourseResourceValue(value.showResource);
          }
        });
      }
    });
  }, [selectedCourse]);

  useEffect(() => {
    EducrackAPI.category
      .activeList({ course: selectedCourse?._id, all: true })
      .then(({ status, data }: any) => {
        if (status === 200) {
          const subMenu: any = [];
          data.categories.map((item: any) => {
            subMenu.push({
              icon: EDU_PRO,
              activeIcon: EDU_PRO_ACTIVE,
              name: item.name,
              link: `/resource/${item._id}`,
              setMenu: true,
            });
          });
          setCategoryList(subMenu);
        }
      });
  }, [selectedCourse]);

  const menu = [
    {
      name: "",
      link: "",
      items: [
        {
          name: "Dashboard",
          icon: DASHBOARD,
          activeIcon: DASHBOARD_ACTIVE,
          link: "/dashboard",
          resource: "COURSE",
        },
        {
          name: "My Courses",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/edupro",
          module: "Manage Courses",
          resource: "COURSE",
        },
        {
          name: "Mentors",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/mentors",
        },
        {
          name: "Resource",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "#",
          isElevated: true,
          items: categoryList,
        },
        // {
        //   name: "Live Class",
        //   icon: MY_GOALS,
        //   activeIcon: MY_GOALS_ACTIVE,
        //   link: "/course/remote-class",
        //   resource: "LIVE_CLASS",
        // },
        {
          name: "My Assignment",
          icon: ASSIGNMENT,
          activeIcon: ASSIGNMENT_ACTIVE,
          link: "/myAssignment",
          resource: "MANAGE_ASSIGNMENTS"
        },
        {
          name: "Free Resources",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/free-resource",
          module: "FreeResource",
          resource: "MANAGE_FREE_RESOURCE",
        },
        {
          name: "My Resume",
          icon: RESUME,
          activeIcon: RESUME_ACTIVE,
          link: "/resume",
          resource: "MY_RESUME",
        },
        {
          name: "Progress",
          icon: PROGRESS,
          activeIcon: PROGRESS_ACTIVE,
          link: "/progress",
          resource: [
            "MANAGE_BATCH",
            "LIVE_SESSIONS",
            "INTERVIEW",
            "TESTS",
            "TEST_BUNDLES",
          ],
        },

        {
          name: "Reward Points",
          icon: REWARD,
          activeIcon: REWARD_ACTIVE,
          link: "/rewardPoints",
          isDisabled: true,
        },
        
        {
          name: "Resume Template",
          icon: RESUME,
          activeIcon: RESUME_ACTIVE,
          link: "/resume-template",
          resource: "MY_RESUME",
        },
        {
          name: "Mock Interviews",
          icon: MOCK,
          activeIcon: MOCK_ACTIVE,
          link: "/course/interviews/",
          module: "Interview",
          resource: "INTERVIEW",
        },
        {
          name: "All Courses",
          icon: MY_GOALS,
          activeIcon: MY_GOALS_ACTIVE,
          link: "/mygoals",
          resource: "COURSE",
        },
        {
          name: "Support",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/support",
          resource: "SUPPORT",
        },
      ],
    },
  ];
  const catMenu = [
    {
      name: "",
      link: "",
      items: [
        {
          name: "Dashboard",
          icon: DASHBOARD,
          activeIcon: DASHBOARD_ACTIVE,
          link: "/dashboard",
          resource: "COURSE",
        },
        {
          name: "My Courses",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/edupro",
          module: "Manage Courses",
          resource: "COURSE",
        },
        {
          name: "Mentors",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/mentors",
        },
        {
          name: "Resource",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "#",
          isElevated: true,
          items: categoryList,
        },
        {
          name: "My Assignment",
          icon: ASSIGNMENT,
          activeIcon: ASSIGNMENT_ACTIVE,
          link: "/myAssignment",
          resource: "MANAGE_ASSIGNMENTS"
        },
        {
          name: "Free Resources",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/free-resource",
          module: "FreeResource",
          resource: "MANAGE_FREE_RESOURCE",
        },
        {
          name: "Progress",
          icon: PROGRESS,
          activeIcon: PROGRESS_ACTIVE,
          link: "/progress",
          resource: [
            "MANAGE_BATCH",
            "LIVE_SESSIONS",
            "INTERVIEW",
            "TESTS",
            "TEST_BUNDLES",
          ],
        },

        {
          name: "Reward Points",
          icon: REWARD,
          activeIcon: REWARD_ACTIVE,
          link: "/rewardPoints",
          isDisabled: true,
        },
        {
          name: "Mock Interviews",
          icon: MOCK,
          activeIcon: MOCK_ACTIVE,
          link: "/course/interviews/",
          module: "Interview",
          resource: "INTERVIEW",
        },
        {
          name: "Support",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/support",
          resource: "SUPPORT",
        },
      ],
    },
  ];

  const noResouceMenu = [
    {
      name: "",
      link: "",
      items: [
        {
          name: "Dashboard",
          icon: DASHBOARD,
          activeIcon: DASHBOARD_ACTIVE,
          link: "/dashboard",
          resource: "COURSE",
        },
        {
          name: "My Courses",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/edupro",
          module: "Manage Courses",
          resource: "COURSE",
        },
        // {
        //   name: "Mentors",
        //   icon: EDU_PRO,
        //   activeIcon: EDU_PRO_ACTIVE,
        //   link: "/mentors",
        // },
        // {
        //   name: "Live Class",
        //   icon: MY_GOALS,
        //   activeIcon: MY_GOALS_ACTIVE,
        //   link: "/course/remote-class",
        //   resource: "LIVE_CLASS",
        // },
        {
          name: "My Assignment",
          icon: ASSIGNMENT,
          activeIcon: ASSIGNMENT_ACTIVE,
          link: "/myAssignment",
          resource: "MANAGE_ASSIGNMENTS"
        },
        // {
        //   name: "Free Resources",
        //   icon: EDU_PRO,
        //   activeIcon: EDU_PRO_ACTIVE,
        //   link: "/free-resource",
        // },
        {
          name: "Progress",
          icon: PROGRESS,
          activeIcon: PROGRESS_ACTIVE,
          link: "/progress",
          resource: [
            "MANAGE_BATCH",
            "LIVE_SESSIONS",
            "INTERVIEW",
            "TESTS",
            "TEST_BUNDLES",
          ],
        },
        {
          name: "Reward Points",
          icon: REWARD,
          activeIcon: REWARD_ACTIVE,
          link: "/rewardPoints",

          isDisabled: true,
        },
        {
          name: "My Resume",
          icon: RESUME,
          activeIcon: RESUME_ACTIVE,
          link: "/resume",
          resource: "MY_RESUME",
        },
        {
          name: "Resume Template",
          icon: RESUME,
          activeIcon: RESUME_ACTIVE,
          link: "/resume-template",
          resource: "MY_RESUME",
        },
        {
          name: "Mock Interviews",
          icon: MOCK,
          activeIcon: MOCK_ACTIVE,
          link: "/course/interviews/",
          module: "Interview",
          resource: "INTERVIEW",
        },
        {
          name: "Support",
          icon: EDU_PRO,
          activeIcon: EDU_PRO_ACTIVE,
          link: "/support",
          resource: "SUPPORT",
        },
        // {
        //   name: "Edu Courses",
        //   icon: MY_GOALS,
        //   activeIcon: MY_GOALS_ACTIVE,
        //   link: "/mygoals",
        // },
      ],
    },
  ];

  const toggleDrawer = () => {
    setDrawer((prevState) => !prevState);
  };
  const absoluteUrl = window.location.href
  useEffect(() => {
    if (courseResouceValue) {
      let data:any = ''
      if(absoluteUrl.includes('https://student.cat4me.com/')){
        data = catMenu;
      }else{
        data = courseResouceValue ? menu : noResouceMenu;
      }
      setMenuList(data);
    }
  }, [courseResouceValue]);

  if (!currentUser) {
    return null;
  }

  return (
    <main className="student-layout">
      <Sidebar
        toggleDrawer={drawer}
        onCourseSelect={onCourseSelect}
        menu={menuList}
      />
      <section className="student-layout_wrap">
        <TopAppBarUser
          toggleDrawer={toggleDrawer}
          onCourseSelect={onCourseSelect}
        />
        <section className="student-layout_wrapper">
          <div className="wrapper-content" style={{ maxWidth: contentWidth }}>
            {children}
          </div>
        </section>
      </section>
    </main>
  );
};

export default Layout;
