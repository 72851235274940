import React from "react";
import { Redirect } from "react-router-dom";
import AuthAPI from "./api/auth";
import {
  // BatchCreate,
  // BatchEdit,
  //BatchListPage,
  EduPreviewFreeResource,
  PreviewBatch,
  StuBatchDetailsPage,
  StuBatchListPage,
  StuMentorListPage,
  StuMentorDetailsPage,
  StuProgramDetailsPage,
} from "./components/batch";
import {
  StuResourceDetails,
  StuResourceListDetails,
  StuResourceDetailPage,
} from "./components/resource";
import Dashboard from "./components/dashboard/dashboard";
import { DoubtList } from "./components/doubt";
import {
  StudentInterviewAttempts,
  StudentInterviewDetailsToSubscribe,
  StudentManageInterviewList,
  StudentResumePreviews,
  StudentResumeTemplateList,
  StudentResumeUpdate,
} from "./components/interviewResumeStudent";
import { StudLiveSessions, StudSessionVideo } from "./components/liveSession";
import {
  CreatePasswordPage,
  ForgotPasswordPage,
  LoginPage,
  OTPVerifyPage,
  ResetPasswordPage,
} from "./components/login";
import Logout from "./components/logout/logout";
// import Layout from "./components/layout/layout";
import PaymentStatus from "./components/payment/PaymentStatus";
import Register from "./components/register/register";
import {
  ReportBatch,
  ReportBatchList,
  ReportEducatorDetail,
  ReportEducatorList,
  ReportStudentDetail,
  ReportStudentList,
  ReportTestList,
  ReportTestReports,
} from "./components/reports";
import { ComplaintSection, Replies, TicketList } from "./components/support";
import {
  CommingSoon,
  CourseSelectionPage,
  DSStudentAttemptTest,
  MyGoalsPage,
  StudentProfile,
  StudentProgress,
  StudentTest,
  StudentTestBundleDetails,
  StudentTestBundleDetailsToSub,
  StudentTestBundleList,
  StudentTestInstructions,
  StudentTestResult,
  UserEduPro,
  UserHomePage,
  UserNotifications,
  UserPsychometricTest,
} from "./components/userDashboard";
import { WallList } from "./components/wall";
import ByPassLoginPage from "./components/login/ByPassLogin";
import {
  FreeResourceDetails,
  FreeResourceList,
} from "./components/freeResource";
import AssignedTestInterview from "./components/assigned-test-interview";
import SchorarshipLogin from "./components/scholarship/login";
import ScholarshipOTPVerification from "./components/scholarship/otpverification";
import ScholarshipUserInfo from "./components/scholarship/personalInfo";

// const LoginPage = React.lazy(() =>
//   import("./components/login").then((module) => ({ default: module.LoginPage }))
// );
// const ForgotPasswordPage = React.lazy(() =>
//   import("./components/login").then((module) => ({
//     default: module.ForgotPasswordPage,
//   }))
// );
// const ResetPasswordPage = React.lazy(() =>
//   import("./components/login").then((module) => ({
//     default: module.ResetPasswordPage,
//   }))
// );
// const VerifyEmailPage = React.lazy(() =>
//   import("./components/login").then((module) => ({
//     default: module.VerifyEmailPage,
//   }))
// );

// const Dashboard = React.lazy(() => import("./components/dashboard/dashboard"));

// const Register = React.lazy(() => import("./components/register/register"));

// const Logout = React.lazy(() => import("./components/logout/logout"));

// const StudLiveSessions = React.lazy(() =>
//   import("./components/liveSession").then((module) => ({
//     default: module.StudLiveSessions,
//   }))
// );
// const StudSessionVideo = React.lazy(() =>
//   import("./components/liveSession").then((module) => ({
//     default: module.StudSessionVideo,
//   }))
// );

// const WallList = React.lazy(() =>
//   import("./components/wall").then((module) => ({ default: module.WallList }))
// );

// const DoubtList = React.lazy(() =>
//   import("./components/doubt").then((module) => ({ default: module.DoubtList }))
// );

// const CommingSoon = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.CommingSoon,
//   }))
// );
// const UserHomePage = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.UserHomePage,
//   }))
// );
// const MyGoalsPage = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.MyGoalsPage,
//   }))
// );
// const SettingsPage = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.SettingsPage,
//   }))
// );
// const CourseSelectionPage = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.CourseSelectionPage,
//   }))
// );
// const StudentTestBundleList = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentTestBundleList,
//   }))
// );
// const StudentTestBundleDetails = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentTestBundleDetails,
//   }))
// );
// const StudentTestBundleDetailsToSub = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentTestBundleDetailsToSub,
//   }))
// );
// const StudentTest = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentTest,
//   }))
// );
// const StudentTestInstructions = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentTestInstructions,
//   }))
// );
// const StudentTestResult = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentTestResult,
//   }))
// );
// const UserAssignment = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.UserAssignment,
//   }))
// );
// const UserAssignmentPreview = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.UserAssignmentPreview,
//   }))
// );
// const UserEduPro = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.UserEduPro,
//   }))
// );
// const StudentProgress = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentProgress,
//   }))
// );
// const UserNotifications = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.UserNotifications,
//   }))
// );
// const StudentProfile = React.lazy(() =>
//   import("./components/userDashboard").then((module) => ({
//     default: module.StudentProfile,
//   }))
// );

// const StudentManageInterviewList = React.lazy(() =>
//   import("./components/interviewResumeStudent").then((module) => ({
//     default: module.StudentManageInterviewList,
//   }))
// );
// const StudentInterviewDetailsToSubscribe = React.lazy(() =>
//   import("./components/interviewResumeStudent").then((module) => ({
//     default: module.StudentInterviewDetailsToSubscribe,
//   }))
// );
// const StudentInterviewAttempts = React.lazy(() =>
//   import("./components/interviewResumeStudent").then((module) => ({
//     default: module.StudentInterviewAttempts,
//   }))
// );
// const StudentResumePreviews = React.lazy(() =>
//   import("./components/interviewResumeStudent").then((module) => ({
//     default: module.StudentResumePreviews,
//   }))
// );
// const StudentResumeUpdate = React.lazy(() =>
//   import("./components/interviewResumeStudent").then((module) => ({
//     default: module.StudentResumeUpdate,
//   }))
// );

// const TicketList = React.lazy(() =>
//   import("./components/support").then((module) => ({
//     default: module.TicketList,
//   }))
// );
// const ComplaintSection = React.lazy(() =>
//   import("./components/support").then((module) => ({
//     default: module.ComplaintSection,
//   }))
// );
// const Replies = React.lazy(() =>
//   import("./components/support").then((module) => ({ default: module.Replies }))
// );

// const ReportBatchList = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportBatchList,
//   }))
// );
// const ReportBatch = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportBatch,
//   }))
// );
// const ReportEducatorList = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportEducatorList,
//   }))
// );
// const ReportEducatorDetail = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportEducatorDetail,
//   }))
// );
// const ReportStudentList = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportStudentList,
//   }))
// );
// const ReportStudentDetail = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportStudentDetail,
//   }))
// );
// const ReportTestList = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportTestList,
//   }))
// );
// const ReportTestReports = React.lazy(() =>
//   import("./components/reports").then((module) => ({
//     default: module.ReportTestReports,
//   }))
// );

// const BatchListPage = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.BatchListPage,
//   }))
// );
// const BatchCreate = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.BatchCreate,
//   }))
// );
// const BatchEdit = React.lazy(() =>
//   import("./components/batch").then((module) => ({ default: module.BatchEdit }))
// );
// const PreviewBatch = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.PreviewBatch,
//   }))
// );
// const EduPreviewFreeResource = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.EduPreviewFreeResource,
//   }))
// );
// const StuBatchListPage = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.StuBatchListPage,
//   }))
// );
// const StuBatchDetailsPage = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.StuBatchDetailsPage,
//   }))
// );
// const StuMentorDetailsPage = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.StuMentorDetailsPage,
//   }))
// );
// const StuResourceDetails = React.lazy(() =>
//   import("./components/batch").then((module) => ({
//     default: module.StuResourceDetails,
//   }))
// );

const routes = [
  {
    component: ScholarshipOTPVerification,
    route: "/scholarship/otp-verify",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ScholarshipUserInfo,
    route: "/scholarship/testdetail",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: SchorarshipLogin,
    route: "/scholarship/:centerId?",
    routeProps: { exact: true },
    props: {},
  },
  
  {
    component: () => {
      const currentUser = AuthAPI.getCurrentUser();
      return <Redirect to={AuthAPI.getRedirectUrl(currentUser)} />;
    },
    route: "/",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: LoginPage,
    route: "/login",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ForgotPasswordPage,
    route: "/forgot-password",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: OTPVerifyPage,
    route: "/otp-verify",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: CreatePasswordPage,
    route: "/create-password/",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ResetPasswordPage,
    route: "/reset-password/:userId/:token",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: Logout,
    route: "/logout",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: Register,
    route: "/personal-detail",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: CourseSelectionPage,
    route: "/course/selection",
    isProtected: true,
    routeProps: { exact: true },
    props: {},
  },

  {
    component: Dashboard,
    layout: true,
    isProtected: true,
    route: "/dashboard",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: StudentProfile,
    layout: true,
    isProtected: true,
    route: "/my-profile",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: StudLiveSessions,
    isProtected: true,
    layout: true,
    route: "/course/remote-class",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudSessionVideo,
    isProtected: true,
    // layout: true,
    route: "/course/remote-class/resource/:id",
    routeProps: { exact: true },
    props: {},
    // resource: "LIVE_CLASS",
  },
  {
    component: WallList,
    layout: true,
    isProtected: true,
    route: "/wall",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: DoubtList,
    layout: true,
    isProtected: true,
    route: "/doubt",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: UserHomePage,
    layout: true,
    isProtected: true,
    route: "/home",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: MyGoalsPage,
    layout: true,
    isProtected: true,
    route: "/mygoals",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: CommingSoon,
    layout: true,
    isProtected: true,
    route: "/liveClassCalendar",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuMentorDetailsPage,
    layout: true,
    isProtected: true,
    route: "/course/mentorDetails/:id",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: StudentProgress,
    layout: true,
    isProtected: true,
    route: "/progress",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: CommingSoon,
    layout: true,
    isProtected: true,
    route: "/rewardPoints",
    routeProps: { exact: true },
    props: {},
  },
  // {
  //   component: BatchListPage,
  //   layout: true,
  //   isProtected: true,
  //   route: "/batch",
  //   routeProps: { exact: true },
  //   props: {},
  // },
  // {
  //   component: BatchCreate,
  //   layout: true,
  //   isProtected: true,
  //   route: "/batch/create/:batchType",
  //   routeProps: { exact: true },
  //   props: {},
  // },
  // {
  //   component: BatchEdit,
  //   layout: true,
  //   isProtected: true,
  //   route: "/batch/:id",
  //   routeProps: { exact: true },
  //   props: {},
  // },
  {
    component: PreviewBatch,
    layout: true,
    isProtected: true,
    route: "/batch/preview/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: EduPreviewFreeResource,
    layout: true,
    isProtected: true,
    route: "/batch/resource/preview/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuResourceDetails,
    layout: true,
    isProtected: true,
    // /course/batch/${id}/resource/${_id}
    route: "/course/batch/:id/resource/:_id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuBatchListPage,
    layout: true,
    isProtected: true,
    route: "/course/batch",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuMentorListPage,
    layout: true,
    isProtected: true,
    route: "/mentors",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuResourceListDetails,
    layout: true,
    isProtected: true,
    route: "/resource/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuResourceDetailPage,
    layout: true,
    isProtected: true,
    route: "/view-resource/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuBatchDetailsPage,
    layout: true,
    isProtected: true,
    route: "/course/batch/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StuProgramDetailsPage,
    layout: true,
    isProtected: true,
    route: "/course/program/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: WallList,
    layout: true,
    isProtected: true,
    route: "/course/wall",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: DoubtList,
    layout: true,
    isProtected: true,
    route: "/course/doubt",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: CommingSoon,
    layout: true,
    isProtected: true,
    route: "/course/liveclasses",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTestBundleList,
    layout: true,
    isProtected: true,
    route: "/course/tests",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTestResult,
    // layout: true,
    isProtected: true,
    route: "/course/test/result/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTestBundleDetails,
    layout: true,
    isProtected: true,
    route: "/course/tests/details/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTestBundleDetailsToSub,
    layout: true,
    isProtected: true,
    route: "/course/tests/sub/details/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTest,
    // layout: true,
    isProtected: true,
    route: "/course/test/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTest,
    // layout: true,
    isProtected: false,
    route: "/shinkan/test/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTestInstructions,
    // layout: true,
    isProtected: true,
    route: "/course/test/instructions/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: DSStudentAttemptTest,
    // layout: Layout,
    isProtected: true,
    route: "/free-resource/test/:freeResourceId/:testId",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: StudentManageInterviewList,
    layout: true,
    isProtected: true,
    route: "/course/interviews",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentInterviewDetailsToSubscribe,
    layout: true,
    isProtected: true,
    route: "/course/interviews/details/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentInterviewAttempts,
    layout: true,
    isProtected: true,
    route: "/course/interviews/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentResumePreviews,
    // layout: true,
    isProtected: true,
    route: "/resume-preview",
    routeProps: { exact: false },
    props: {},
  },
  {
    component: StudentResumeTemplateList,
    layout: true,
    isProtected: true,
    route: "/resume-template",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentResumeUpdate,
    layout: true,
    isProtected: true,
    route: "/resume/",
    routeProps: { exact: false },
    props: {},
  },
  {
    component: UserEduPro,
    layout: true,
    isProtected: true,
    route: "/edupro",
    routeProps: { exact: false },
    props: {},
  },

  {
    component: TicketList,
    layout: true,
    isProtected: true,
    route: "/support",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ComplaintSection,
    layout: true,
    isProtected: true,
    route: "/support/create",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: Replies,
    layout: true,
    isProtected: true,
    route: "/support/:ticketId",
    routeProps: { exact: true },
    props: {},
  },

  {
    component: PaymentStatus,
    layout: true,
    isProtected: true,
    route: "/payment/status/:orderId",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: UserNotifications,
    layout: true,
    isProtected: true,
    route: "/notifications",
    routeProps: { exact: true },
    props: {},
    // resource: "SUPPORT",   // Need to change this on the bases of NOTIFICATIONS
  },
  {
    component: ReportBatchList,
    layout: true,
    isProtected: true,
    route: "/reports/batches",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ReportBatch,
    layout: true,
    isProtected: true,
    route: "/reports/batches/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ReportTestList,
    layout: true,
    isProtected: true,
    route: "/reports/tests",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ReportTestReports,
    layout: true,
    isProtected: true,
    route: "/reports/tests/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ReportEducatorList,
    layout: true,
    isProtected: true,
    route: "/reports/educators",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ReportEducatorDetail,
    layout: true,
    isProtected: true,
    route: "/reports/educators/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ReportStudentList,
    layout: true,
    isProtected: true,
    route: "/reports/students",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ReportStudentDetail,
    layout: true,
    isProtected: true,
    route: "/reports/students/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: FreeResourceList,
    layout: true,
    isProtected: true,
    route: "/free-resource",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: DSStudentAttemptTest,
    // layout: Layout,
    isProtected: true,
    route: "/free-resource/test/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: FreeResourceDetails,
    layout: true,
    isProtected: true,
    route: "/free-resource/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: ByPassLoginPage,
    layout: false,
    isProtected: false,
    route: "/admin-login",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: AssignedTestInterview,
    layout: false,
    isProtected: false,
    route: "/test/attempt",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: AssignedTestInterview,
    layout: false,
    isProtected: false,
    route: "/interview/attempt",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: AssignedTestInterview,
    layout: false,
    isProtected: false,
    route: "/psychometric-test/attempt",
    routeProps: { exact: true },
    props: { isPsychometric: true },
  },
  {
    component: UserPsychometricTest,
    // layout: true,
    isProtected: true,
    route: "/psychometric-test/:id",
    routeProps: { exact: true },
    props: {},
  },
  {
    component: StudentTestInstructions,
    // layout: true,
    isProtected: true,
    route: "/psychometric-test/instructions/:id",
    routeProps: { exact: true },
    props: { isPsychometric: true },
  },
];

export default routes;
