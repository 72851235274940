import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  InputHTMLAttributes,
  KeyboardEvent,
  ChangeEvent,
} from "react";
import { useHistory } from "react-router-dom";
import EducrackAPI from "@lipihipi/client-sdk";
import LeftPanel from "../component/LeftPanel";
import { Loader } from "@lipihipi/ec-ui";
import AuthAPI from "../../../api/auth";

function usePrevious<T>(value?: T) {
  const ref = useRef<T>();
  useEffect(() => {
    //On load component sendotp and remove senOtp button.write another useeffctech()
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface OTPInputProps extends InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
}

const SingleOTPInput = memo((props: OTPInputProps) => {
  const { focus, autoFocus, ...rest } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);
  useEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return (
    <li>
      <input
        type="number"
        className="form-control"
        ref={inputRef}
        maxLength={1}
        {...rest}
      />
    </li>
  );
});

const ScholarshipOTPVerification = () => {
  const length = 4;
  const seconds = 30;

  const [activeInput, setActiveInput] = useState(0);
  const [timeLeft, setTimeLeft] = useState(seconds);
  const [error, setError] = useState(false);
  const [otpValues, setOTPValues] = useState(Array<string>(length).fill(""));
  const [OTP, setOTP] = useState("");
  const [otpInput, setOtpInput] = useState(false);
  const history = useHistory();
  const [item, setItem] = useState<any>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const user: any = localStorage.getItem("user");
  useEffect(() => {
    if (user) {
      setItem(JSON.parse(user));
    }
  }, [user]);
  const intervalRef = useRef<any>();

  let values: any = item._id;
  useEffect(() => {
    if (item.mobile) {
      handleSubmit(values);
    }
    //New code:-//On load component sendotp and remove senOtp button.write another useeffctech()
  }, [item]);

  // handle countdown timer
  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    intervalRef.current = intervalId;
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // handle Resend OTP
  const onResendOTP = () => {
    setTimeLeft(seconds);
    setLoading(true);
    //onResend();
    handleSubmit(values);
  };

  // Helper to return OTP from inputs
  const handleOtpChange = (otp: string[]) => {
    const otpValue = otp.join("");
    setOTP(otpValue);
  };

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str: string) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  // Focus `inputIndex` input
  const focusInput = useCallback(
    (inputIndex: number) => {
      const selectedIndex = Math.max(Math.min(length - 1, inputIndex), 0);
      setActiveInput(selectedIndex);
    },
    [length]
  );

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  // Handle onFocus input
  const handleOnFocus = useCallback(
    (index: number) => () => {
      focusInput(index);
    },
    [focusInput]
  );

  // Handle onChange value for each input
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;
      const val = !value || /\d/.test(value) ? value : "";
      if (!val) {
        e.preventDefault();
        return;
      }
      changeCodeAtFocus(val);
      focusNextInput();
    },
    [changeCodeAtFocus, focusNextInput]
  );

  // Hanlde onBlur input
  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  // Handle onKeyDown input
  const handleOnKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      switch (e.key) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case " ": {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  /****************************************************************************************************** */

  const handleSubmit = async (values: any) => {
    const response: any = await EducrackAPI.user.sendOTP(values);
    console.log("respo of OTP SEND=", response);
    if (response.status == 200) {
      setLoading(false);
      setOtpInput(true);
      console.log("SMS SEND succesfully");
    } else {
      console.log("ERROR IN SMS SEND");
    }
  };

  const onSubmitOTP = useCallback(async () => {
    var values: any = {
      id: item._id,
      otp: OTP,
      getToken: true,
    };
    try {
      setLoading(true);
      const response: any = await EducrackAPI.user.verifyMobile(values);
      if (response.status) {
        setLoading(false);
        localStorage.setItem("token", response.data.token);
        AuthAPI.isAuthenticated = true;
        EducrackAPI.setToken(response.data.token);
        history.push("/scholarship/testdetail");
      }
    } catch (err) {
      if (err.data && err.status === 422) {
        setError(true);
        setLoading(false);
      }
    }
    clearInterval(intervalRef.current);
  }, [OTP]);
  /****************************************************************************************************** */
  return (
    <main className="user-structure">
      {isLoading && <Loader />}
      <LeftPanel logo={'http://ed-39p6doej4mr2.s3.amazonaws.com/f26aeabb-4417-4a64-9500-ef43a0a8453e.jpeg'}/>
      <div className="user-structure--form">
        <div className="wrap">
          {/* <h2>Login to Educrack</h2> */}
          <h3>Enter the 4 digit OTP code</h3>
          <p>
            Sent to +91 - {item.mobile}{" "}
            <a href="/scholarship" className="btn btn-success-outline">
              Change
            </a>
          </p>
          {otpInput && (
            <>
              <div className="otp-box">
                {/* <p>{`Send to ${item.mobile}`}<a href='/scholarship/login' className='btn btn-success-outline'>Change</a></p> */}
                <ul>
                  {Array(4)
                    .fill("")
                    .map((_, index) => (
                      <SingleOTPInput
                        key={`SingleInput-${index}`}
                        focus={activeInput === index}
                        autoFocus={true}
                        value={otpValues && otpValues[index]}
                        onFocus={handleOnFocus(index)}
                        onChange={handleOnChange}
                        onKeyDown={handleOnKeyDown}
                        onBlur={onBlur}
                      />
                    ))}
                </ul>
                {error && <p>Invalid Otp</p>}
                {!!timeLeft && (
                  <p>{`Auto detecting the code 00:${timeLeft} seconds`}</p>
                )}
                {(!timeLeft || error) && (
                  <>
                    <span>Not Received?</span>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={onResendOTP}
                    >
                      Resend OTP
                    </button>
                  </>
                )}
              </div>
              <div className="button-group">
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={OTP.length !== length}
                  onClick={onSubmitOTP}
                  style={{ width: "100%" }}
                >
                  Save & Proceed
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
};
export default ScholarshipOTPVerification;
