import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "@lipihipi/form";
import LeftPanel from "../component/LeftPanel";
import EducrackAPI from "@lipihipi/client-sdk";
import validationSchema from "./validationSchema";
import { Loader } from "@lipihipi/ec-ui";

const ScholarshipUserInfo = () => {
  // const history = useHistory();
  const [initialValues, setInitialValues] = useState({
    name: "",
    email: "",
  });
  const [buttonEnable, setButtonEnable] = useState(true);
  const [currentUser, setCurrentUser] = useState<any>();
  const [testOptions, setTestOptions] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState<boolean>(true);
  const center: any = localStorage.getItem("center");
  useEffect(() => {
    const user: any = localStorage.getItem("user");

    if (user) {
      EducrackAPI.user.me().then((res: any) => {
        setLoading(false);
        setCurrentUser(res.data);
        setInitialValues({
          name: res.data.name,
          email: res.data.email,
        });
      });
      // const currentUser: any = AuthAPI.getCurrentUser();
      //   console.log(currentUser)
    }
  }, []);
  useEffect(() => {
    EducrackAPI.test.publicList({ all: true }).then((res: any) => {
      const options = res.data.tests.map((test: any) => ({
        label: test.name,
        value: test._id,
      }));
      setTestOptions(options);
    });
  }, []);
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      if(center){
        values.center = center
      }
      const response = await EducrackAPI.user.setUserData(values);

      if (response.status === 200) {
        const res: any = await EducrackAPI.userTest.create({
          testId: values.test,
        });
        const _window = window as any;

        const { enrollmentId } = res?.data;

        const { data } = await EducrackAPI.testAttempt.list({
          test: enrollmentId,
        });
        if (data) {
          localStorage.setItem("isScholarshipTest", "true");
          _window.open(`/course/test/instructions/${enrollmentId}`, "_self");
        }
      }
    } catch (err) {
      setLoading(false);
      setError(err.data.message);
    }
  };
  return (
    <main className="user-structure">
      {isLoading && <Loader />}
      <LeftPanel logo={'http://ed-39p6doej4mr2.s3.amazonaws.com/f26aeabb-4417-4a64-9500-ef43a0a8453e.jpeg'}/>
      <div className="user-structure--form">
        <div className="wrap">
          <h3>Enter your details to proceed</h3>
          <Form
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {!currentUser?.name && (
              <Input
                id="fname"
                name="name"
                type="text"
                label="Full Name"
                required={true}
              />
            )}
            {!currentUser?.email && (
              <Input
                id="lname"
                name="email"
                type="text"
                label="Email Id"
                required={true}
              />
            )}

            {error && <div className="invalid-feedback d-block">{error}</div>}
            <Select
              options={testOptions}
              name="test"
              id="test"
              label="Select Test"
              required
              onChange={() => {
                setButtonEnable(false);
              }}
            />
            <div className="button-group">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "100%" }}
                disabled={buttonEnable}
              >
                Save & Proceed
              </button>
            </div>
          </Form>
        </div>
      </div>
    </main>
  );
};
export default ScholarshipUserInfo;
